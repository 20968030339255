import React from "react"
import Image from 'next/image'

export default function FAQs() {
    const currentYear = (new Date).getFullYear();
    const taxYear = currentYear - 1;
    return (
        <>
            <div className="faqs-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="faq">
                                <h2><Image src="/images/icon3.png" width="101" height="92"
                                           alt="icon-1"/> &nbsp; &nbsp;Why People Submit Tax
                                    Extensions</h2>
                                <hr/>
                                <p>Life gets busy, and finances can be complicated. If you find yourself in either of
                                    these two
                                    situations, rest assured as you may be eligible to receive additional time to
                                    compile the
                                    necessary paperwork for your returns. It is important to note that this is an
                                    extension to file,
                                    not an extension to pay any taxes due.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="faq-1">
                                <h2><Image src="/images/icon4.png" alt="icon-2" width="100"
                                           height="100"/> &nbsp; &nbsp;Did You Know You Can Have
                                    An Extra 5
                                    Months?</h2>
                                <hr/>
                                <p>Federal income tax extensions are good for five months. With the {` ${taxYear} `} 
                                    tax year deadline being May 17th, {` ${currentYear} `}
                                    , that means you have until October 15th, {` ${currentYear} `} 
                                    to complete the task. Think you could use the extra time?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
